const messagesZh = {
	tracking_number: '貨運追蹤號',
	hello: 'Hello world',
	language: '語言',
	currency: '貨幣',
	copyright: '版權宣告',
	allright: '版權所有。',
	sort: '排序',
	filter: '過濾',
	EMAIL: '聯絡電郵',
	sort_by: '排序',
	refine_by: '過濾選項',
	price: '價格',
	average_rating: '平均評分',
	reviews: '評論',
	item_code: '產品編碼',
	qty: '數量',
	register_enable: '註冊賬戶激活',
	wholesale_prices: '批發價',
	add_to_cart: '加入購物車',
	add_to_favorites: '加入收藏',
	description: '描述',
	reviews: '評論',
	shipping_payment: '貨運 & 支付',
	customer_who_also_bought: '買了這個產品的人還買了什麽',
	add_review: '增加評論',
	view_all_review: '檢視所有評論',
	you_must_chosen: '您必須選擇',
	cart: '購物車',
	discount_codes: '摺扣',
	subtotal: '小計',
	sub_weight: '總重',
	sub_volume: '總體積',
	shipping_cost: '運費',
	discount: '摺扣',
	grand_total: '總計',
	proceed_to_pay: '繼續付款',
	checkout: '下單頁面',
	welcome_to_checkout: '歡迎來到結帳，填寫下面的字段來完成您的購買！',
	already_registered: '已經註冊？ 點選此處登入',
	shipping_address: '發貨地址',
	new_address: '新地址',
	first_name: '名',
	last_name: '姓',
	email_address: '郵箱',
	emailAddress: '電郵地址',
	telephone: '電話',
	street: '街道',
	country: '國家',
	state: '省',
	city: '城市',
	zip_code: '郵編',
	create_an_account: '註冊',
	password: '密碼',
	confirm_password: '確認密碼',
	payment_method: '支付方式',
	shipping_method: '貨運方式',
	coupon_codes: '優惠券代碼（可選）',
	enter_your_coupon_code: '輸入您的優惠券代碼',
	review_your_order: '檢視妳的訂單',
	place_order_now: '現在下單',
	return_policy: '退貨條約',
	privacy_policy: '隱私條約',
	contact_us: '聯絡我們',
	about_us: '關於我們',
	my_favorite: '我的收藏',
	my_order: '我的訂單',
	shopping_cart: '購物車',
	account: '賬戶',
	home: '首頁',
	sign_in: '登入',
	forgot_password: '忘記密碼？',
	register: '註冊',
	login: '登入',
	register_account: '註冊賬戶',
	my_account: '我的賬戶',
	logout: '退出登入',
	edit_account: '編輯賬戶',
	change_password: '更改密碼',
	add_new_address: '增加新地址',
	default_address: '預設地址',
	name: '名字',
	address: '地址',
	operation: '操作',
	customer_address: '貨運地址',
	reorder: '重新下單',
	view_order: '檢視訂單',
	order_increment_id: '訂單號',
	date: '日期',
	operate: '操作',
	customer_order: '我的訂單',
	order_status: '訂單狀態',
	order_date: '訂單日期',
	shipping_address: '貨運地址',
	shipping_method: '貨運方式',
	payment_method: '支付方式',
	items_ordered: '訂單產品',
	product_image: '產品圖片',
	grand_total: '總計',
	product_info: '產品信息',
	product_review: '產品評論',
	product_favorite: '產品收藏',
	send_authorization_code: '發送授權碼',
	forget_password: '忘記密碼',
	click_here: '點選這裏',
	to_login: '去登陸',
	reset_accout_success: '重置密碼成功，您可以',
	reset_password_success: '充值密碼成功',
	forget_password_send_success: '充值密碼發送成功',
	we_send_message_to_your_email: '我們已經給您的信箱發送了一封郵件',
	follow_the_instructions_provided: '請按照郵件中提供的說明重置您的密碼。',
	donot_receive_email: '沒有收到我們的郵件？',
	click_here_to_retry: '點選這裏重新發送郵件',
	check_your_bulk_junk_email_folder: '檢查您的垃圾郵件檔案夾。',
	confirm_your_identity_to_reset: '如果妳仍然無法找到它，請確認妳的身份重置密碼',
	click: '點選',
	support_center: '幫助中心',
	for_help: '尋求幫助',
	reset_password: '重置密碼',
	our_email_address: '我們的信箱地址',
	contacts: '聯繫我們',
	submit: '提交',
	captcha: '驗證碼',
	review: '評論',
	rate: '評星',
	summary: '概要',
	name: '姓名',
	product_review: '產品評論',
	add_review: '新增評論',
	reviews: '評論',
	thank_you_for_purchase: '您的訂單，我們已經收到，感謝您的購買',
	your_order_is: '您的訂單號',
	receive_an_confirm_email: '您將收到一份訂單確認電子郵件，其中包含您訂單的詳細信息以及跟蹤其進度的鏈接。',
	continue_shopping: '繼續購物',
	order_remark: '訂單備註 (可選)',
	fill_order_remark: '妳可以在下面填寫您的備註信息',
	average_rating: '平均評分',
	stars_5: '5 星',
	stars_4: '4 星',
	stars_3: '3 星',
	stars_2: '2 星',
	stars_1: '1 星',
	arm: '主件',
	accessories: '配件',
	styles: '款式',
	material: '材質',
	Customization: '自選組合',
	pleaseSelect: '請選擇',
	sorting: '綜合排序',
	Filter: 'Filter',
	category: '類別',
	gem: 'Gem',
	Price: '價格',
	getCode: '獲取驗證碼',
	securityCode: '電郵驗証碼',
	signUp: '註冊',
	please: '請輸入',
	pleases: '請選擇',
	terms: '條款和條件',
	privacy: '隱私政策使用',
	Already: '已經註冊？',
	and: '和',
	Agr1: '建立帐户即代表您同意我们根据 {n} 的',
	Agr2: ' 使用您的数据资料',
	agr2: '如果您不希望繼續收到我們的推廣資料，請勾選此框',
	LOGIN: '登入',
	LOGINF: '請登入您的賬戶',
	LOGINFA: '創建一個帳戶',
	LOGINFB: '建立新帳戶以獲取額外優惠, 快速結帳以及訂單查詢及追踪',
	FORGOT: '忘記密碼?',
	FORGOTF: '若要重置密碼，請輸入電子郵件地址',
	ResetPassword: '新密碼',
	ResetPasswordA: '確認新密碼',
	ProceedLog: '繼續登入',
	Information: '個人信息',
	Orders: '我的訂單',
	Appointment: '我的預約',
	SignOut: '退出登入',
	LAA: '歡迎登入,暢享以下購物體驗：',
	LAB: '更輕鬆存取訂購記錄、已儲存產品及其他資料',
	LAC: '使用已儲存的出貨及帳單資料，更快結帳',
	LAD: '個人化設計記錄',
	LAE: '登入',
	LAF: '創建一個帳戶',
	SizeElectionGuide: '尺碼選擇指南',
	addCart: '加入購物車',
	CheckOut: '結賬',
	Recommend: '嚮您推薦',
	Recently: '猜你也喜歡',
	SearchComments: '搜索評論',
	QUERY: '搜索',
	serviceFA: '尋找區內的{n}體驗服務店，預約私人的珠寶顧問服務',
	serviceFB: '查找',
	serviceFC: ' 香港本地包郵',
	serviceFD: '精美禮盒包裝',
	serviceFE: '免費刻字',
	serviceFF: '鑽石證書',
	Details: '基本信息',
	ProductDetails: '產品詳細信息',
	Descriptionworks: '工作的描述',
	OnlineConsultan: 'whatsApp',
	whatsApp: 'whatsApp',
	ShoppingCart: '購物車',
	currentShopping: '當前購物車共',
	currentWorks: '件作品',
	ChooseAll: '全選',
	Validation: '附加證書',
	CHANGE: '更改',
	TOTAL: '總計',
	TAXESDUTIES: '稅項：',
	TAXESDUTIESFA: '對於美國及香港的訂單,以上總金額已包含有關的入口關稅。',
	TAXESDUTIESFB: '對於美國及香港以外的海外訂單,入口關稅及其他清關費用將會由快遞公司在貨物送抵時向您收取。',
	Amount: '數量',
	OrderInformation: '訂單信息',
	Items: '件',
	Subtotal: '小計',
	PromotionCode: '促銷代碼',
	promocode: '輸入促銷代碼獲得摺扣',
	cancel: '取消',
	CONFIRM: '確認',
	NOTE: '註意',
	NOTEFA: '特價貨品/已刻字產品/定制產品均不享有退貨及換貨服務',
	Need: '需要幫忙?',
	NeedFA: '退換貨政策',
	NeedFB: '有關定制產品，我們會電郵給你以確認定制詳情。',
	Remove: '刪除',
	Viewall: '檢視所有',
	Select: '已選擇',
	ShoppingCart: '購物車',
	cartEmpty: '妳的購物車是空的',
	MyDesign: '我的設計',
	designYet: `妳還沒有開始妳的設計。`,
	Starthere: '從這裏開始!',
	GoStudio: '去3D {n}工作室',
	Wishlist: '願望清單',
	listEmpty: '妳的願望清單是空的',
	Accountinformation: '物流信息',
	accountOrderFA: '登入帳戶將用於跟蹤您的訂單狀態',
	accountOrderFB: '訂單一概不得運往郵政信箱。',
	accountOrderFC: '請提供一個送件時將有人簽收的街頭地址。',
	CreateAccount: '創建一個帳戶',
	Email: 'E-mail',
	verificationCode: '獲取驗證碼',
	SecurityCode: '驗證碼',
	Shippinginformation: '配送信息',
	StandardLogistics: '標准物流',
	PickStore: '到店取貨',
	InstructionsStore: '郵寄到門店說明',
	Theeditor: '編輯',
	Title: '稱呼',
	FirstName: '姓',
	LastName: '名稱',
	PhoneNumber: '手機號碼',
	StateProvince: '州/省',
	PostalCode: '郵編',
	AddressLine1: '詳細地址 1',
	AddressLine2: '詳細地址 2',
	PaymentMethod: '支付方式',
	PromoCode: '優惠碼',
	PromoCode1: '優惠金額',
	Postscript: '附言',
	PostscriptFA: '如果您還有其他要求，請在這裏填寫',
	PaymentAgrFA: '已清楚併接受有關',
	PaymentAgrFB: '條款和條件',
	PaymentAgrFC: '包括',
	PaymentAgrFD: '付款',
	PaymentAgrFE: '退貨政策',
	PaymentAgrFF: '勾選此方框，即表示我同意本',
	PaymentAgrFG: '隱私政策',
	PaymentAgrFH: '並同意將所提供的信息用於{n}。',
	paymentworks: '已選{count}件作品進行結算和支付',
	ShippingInsurance: '運輸和保險',
	Findme: '在我附近找一家商店',
	selectLocation: '請選擇位置',
	storesfound: '已為您找到 {count} 家門店',
	CHOOSE: '選擇',
	YourAccount: '您的賬戶',
	MyDetails: '個人信息',
	Orders: '我的訂單',
	Return: '退貨退款',
	MyAppointment: '我的預約',
	Account: '賬戶信息',
	OAA: '您的信箱',
	OAC: '您當前的信箱',
	OAD: '您的密碼',
	OAE: '如要修改密碼，請在下方重新輸入.',
	OAF: '您的新密碼',
	OAG: '再次確認密碼',
	OAH: '您現在的電話號碼是',
	OAI: '新號碼',
	SAVE: '保存',
	Birthday: '生日',
	Relationship: '感情狀態',
	Unmarried: '未婚',
	married: '已婚',
	engagement: '訂婚',
	anniversary: '妳們的結婚紀念日是什麽時候?',
	OrderNo: '訂單號',
	Custom: '定制作品',
	Existing: '現成作品',
	Waiting: '等待付款',
	WaitingFA: '您的訂單已創建，請繼續完成付款；如未能在60分鐘內付款，訂單將自動取消。',
	WaitingFB: '支付處理中',
	WaitingFC: '付款成功',
	WaitingFD: '欺詐',
	InReview: '在審查中',
	InReviewFA: '我們的客戶服務團隊已經收到了您的訂單，目前正在為您審查中。',
	InReviewFB: '請耐心等待',
	wSend: '訂單已確認',
	wSendFA: '客服已經確認您的訂單。我們會盡快安排訂單處理併發貨給您。',
	wGoods: '您的訂單已經發貨',
	wGoodsFA: '請檢查發貨人的跟蹤號檢視物流狀態。',
	wreceived: '您的訂單已收貨',
	wEnd: '您的訂單完成了',
	wEndFA: '簽署併接收訂單。感謝您在{n}購物，希望您喜歡您的新珠寶！請給我們您的意見和建議。我們希望很快再見到您！',
	wExpired: '您的訂單已取消',
	wExpiredFA: '您的訂單已取消',
	wExpiredFB: '已取消',
	paymentOverdue: '您的付款已過期',
	orderComplete: '您的訂單已完成',
	orderShipped: '您的訂單已經發貨',
	OrderConfirmed: '訂單已確認',
	PleasePatient: '請耐心等待',
	PickupCode: '取件代碼',
	RemainingTime: '剩余付款時間',
	ReturnShopping: '回到購物',
	WRITEREVIEW: '寫一個評論',
	ConfirmReceipt: '確認收貨',
	applyRefund: '申請退款',
	CREATE: '創建',
	PAYMENT: '付款',
	ORDERCOMFIRMED: '審查',
	Shipped: '發貨',
	Complete: '完成',
	LogisticsInfo: '物流信息',
	CustomDesign: '自定義設計',
	Setting: '設定',
	CenterStone: '雕刻',
	Engravingfee: '鉆石',
	gender0: '保密',
	gender1: '先生',
	gender2: '女士',
	timelineFA: '* Here shows the standard logistics related information, dynamic configuration by the background',
	Certification: '額外的認證(可選)',
	RefundFA: '申請退貨/退款',
	RefundFB: '收到物品後({count})天內生效，定制物品除外。提交您的申請，我們的客服代錶將回復您的後續行動。',
	RefundFC: '服務類型',
	RefundFD: '退換貨原因',
	RefundFE: '詳細描述',
	RefundFF: '圖片資料',
	RefundFG: '為了幫助我們更好的解決問題，請上載圖片',
	RefundFH: '退回類型',
	salesAfterFA: '在審查中',
	salesAfterFB: '我們的客戶服務團隊已經收到您的退款請求，目前正在為您審查它',
	salesAfterFC: '退款被拒絕',
	salesAfterFD: '很抱歉，您的請求被拒絕了。如果您有任何問題，請聯繫我們的客服代錶。我們希望很快再見到您！',
	salesAfterFE: '退款成功',
	salesAfterFF: '您的退款已被批淮，所要求的金額已通過您原來的支付方式返回給您。請註意查收',
	salesAfterFG: '缺貨',
	// salesAfterFH: '我們非常抱歉。每顆磚石都是獨一無二，您選擇的鉆石已經斷貨，我們已經選擇了幾顆價格相識，4C的鉆石給您替換，仍然不能滿足您的要求，我們將通過您原來的付款方式將金額原路退回。謝謝您的理解，希望很快能見到您！',
	salesAfterFH: '非常抱歉，您的退貨申請被駁回。根據我們的檢測結果，我們無法接受您退回的商品。如果您有任何問題或疑慮，請隨時與我們聯繫，我們會盡力協助您。',
	salesAfterFI: '商家回復：',
	salesAfterFJ: '審查通過',
	salesAfterFK: '審查通過,等待商家退款',
	salesAfterFL: '退款已取消',
	applyTime: '申請時間',
	remark: '備註',
	Actual: '實際退款金額',
	rActual: '應退款金額',
	Application: '我的申請信息',
	VisitingAppointment: '門店預約',
	RequestAppointment: '預約: 選擇您喜歡的店鋪，在友好的從業人員的支援下享受定制設計體驗。填妥錶格併遞交，我們會以電話或電郵與您聯絡確認。',
	Visitingappointment: '聯繫方式',
	Reservationinformation: '預訂信息',
	Appointmenttime: '預約日期及時間',
	Pleaseelaborate: '請詳細說明我們可以如何幫助妳。',
	Yourselection: '您的選擇',
	DoOptional: '妳已經有想法了嗎?(可選)',
	Chooseyou: '選擇一種吸引妳的風格。',
	CONFIRM: '確認',
	Businesshours: '營業時間',
	aFA: '我們會與您聯繫',
	aFB: '我們已收到您的請求，併將在48小時內與您聯繫，安排店內預約。',
	aFC: '您的選擇',
	aFD: '預約成功',
	aFE: '妳好!',
	aFF: '歡迎使用{title}網上店鋪預訂服務。您的“{count}-店”申請已獲批准。',
	aFG: '我的選擇',
	aFH: '店鋪未營業!',
	Purpose: '備註',
	appointmenttime: '預約時間',
	CHOOSESETTING: '選擇這個設定',
	Modify: '修改',
	Compare: '比較',
	DCompare: '取消比較',
	law: '網站政策和法律宣告',
	edu: '教育',
	problem: '常見問題',
	color: '顏色',
	clarity: '凈度',
	cut: '切工',
	symmetry: '對稱',
	polish: '拋光',
	fluor: '熒光',
	drill: '強力搜鉆',
	replace: '重新選擇',
	latest: '最新',
	weight: '重量',
	Ingem: '換寶石',
	shape: '形狀',
	Results: '結果',
	Comparison: '比較',
	indexFA: '自定義分類',
	indexFB: '獨特而有品位的物品，讓妳送給妳的愛人，送給妳的朋友，或搭配不同的時尚風格',
	indexFC: '設計妳自己的戒指',
	indexFD: '提供專屬定制服務，從訂婚鉆戒到結婚戒指，自由創造屬於妳的獨特愛情故事',
	indexFE: '從戒指款式開始',
	indexFF: '從鉆石開始',
	indexFG: '特色介紹',
	indexFH: '把妳愛的光芒放在命運的紅線上，讓我們的故事不一樣',
	indexFI: '發現更多＋',
	indexFJ: '自定義設計',
	indexFK: '設計好了',
	indexFL: '熱銷單品',
	indexFM: '選購鑽石',
	indexFN: '請選擇你的送貨目的地及貨幣',
	indexFO: '從戒托開始',
	Countries: "運往",
	dialogVisibleFA: '戒指尺碼選擇指南',
	dialogVisibleFB: '拿一根不可拉伸的繩子、絲帶、線或一張薄紙。把它舒適的繞在無名指或其他手指上，靠近指關節',
	dialogVisibleFC: '仔細地用鋼筆在琴弦重疊的地方做記號。為了達到更好的准確性，盡可能做最小的標記',
	dialogVisibleFD: '打開標簽的長度。用尺子測量毫米或英寸。在下面輸入您的測量值',
	dialogVisibleFE: '輸入您所測量的值',
	dialogVisibleFF: '計算方式',
	dialogVisibleFG: '測量結果：您的尺碼是',
	dialogVisibleFH: '妳想把測量好的尺寸存到您的賬戶中嗎？',
	dialogVisibleFI: '是的，我想保存他',
	dialogVisibleFJ: '確認',
	failure: '失效',
	Records: '記錄',
	Recommends: '推薦',
	Recommends1: '搭配組合',
	ProductDescription: '產品描述',
	User: '用戶',
	OrderDetail: '訂單詳情',
	Comments: '評論',
	Score: '評分',
	Review: '評價',
	Anonymously: '是否匿名',
	POST: '提交',
	CommentsFA: '這個產品是如何選擇的?',
	CommentsFB: '我選擇它作為一個驚喜/禮物',
	CommentsFC: '我和我的搭檔合作',
	CommentsFD: '我的伴侶為她自己選的',
	CommentsFE: '這是我給自己選的禮物',
	CommentsFF: '從1到10，你有多大可能向你的朋友或同事推薦Ju mart ?',
	CommentsFG: '評論',
	CommentsFH: '這個評論對你有用嗎?',
	ringSetting: '戒托',
	CertificateNumber: '證書編號',
	FindAStore: '體驗服務店',
	Size: '尺寸',
	WEACCEPT: '我們接受以下付款方式',
	CTA: '請勾選協議',
	PAYMENTSUCCESS: '付款成功',
	PAYMENTSUCCESS1: '我們的客戶服務團隊已經收到了您的訂單，目前正在審核。',
	PAYMENTSUCCESS2: '繼續購買',
	PAYMENTFAILURE: '付款失敗',
	PAYMENTFAILURE1: '付款失敗，請重新付款',
	PAYMENTFAILURE2: '繼續付款',
	PAYMENTSUCCESSD: '訂單詳情',
	PAYMENTSUCCESSR: '返回首頁',
	JUMARTEMAIL: 'info@JU-MART.com',
	PAYFA: '配送信息不能為空！',
	PAYFB: '請選擇門店！',
	store: '門店',
	MoreInformation: '更多信息',
	Prompt: '提示',
	Logged: `您還沒有登錄，是否前往登錄？`,
	Exceeding: '字母數字組合，長度最多20個字符',
	Accept: '接受',
	Reject: '拒絕',
	alertdialog: '我們使用 Cookie 為訪客提供最佳的網站體驗。 其中包括分析、功能與定位Cookie，這些項目對於我們的市場行銷工作也會有所助益。 按一下「接受」按鈕同意使用 Cookie。 若要撤回非必要的 Cookie，按一下「拒絕」。',
	ExperienceCustomization: '體驗定制功能',
	MAKEAAPPOINTMENT: '預約',
	ExcludingTaxes: '不含稅',
	ExcludingTaxes1: '不含稅',
	ExcludingTaxes2: '不含稅',
	GoodsRefunded: '商品不能申請退款',
	subscribeFA: '獲得專享優惠和新消息',
	subscribeFB: '你的電子郵件',
	subscribeFC: '登記',
	subscribeFD: '感謝您的訂閱！',
	ExhibitionChain: 'Exhibition Chain type',
	Choose: '選擇',
	Cancels: '取消',
	FindNear: '離我最近的客戶服務中心',
	YouDesigned: '你目前已經設計了',
	ViewAll: '查看所有',
	CompareDesigns: '比較設計',
	YouCrrently: '你現在有',
	Hi: '嗨',
	Nologistics: '沒有物流信息',
	Exploremore: '探索更多',
	originalPrice: '原價',
	Return: '返回列表',
	designFA: '選擇一個',
	designFB: '設置',
	designFC: '鉆石/寶石',
	designFD: '鉆石/寶石',
	designFE: '完整的',
	designFF: '選擇這個鉆石',
	designFG: '該設置無對應鉆石',
	Location: '地址',
	Coffee: '咖啡',
	Browsinghistory: '瀏覽記錄',
	NotFun: '產品不存在或已下架',
}
export default messagesZh